export const correctTimerShow = (number) => {
  if(number > 59){
    const minutes = Math.floor(number / 60);
    const seconds = number % 60;
    return `0${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
  }else {
    return `00:${number < 10 ? `0${number}` : number}`
  }
};

export const correctInitialTimeShow = (gameDuration) => {
    return gameDuration === 1 ? `00:59` : `02:30` 
}