import moment from 'moment';
import {
  correctView,
  digitsCount,
  howToPlay,
  changeStartAndProfileBalance,
  getBet,
  profileBalance,
  //betInfo,
  raceId,
  winNumber,
  history,
  addCurrentGameHistoryAndMyHistory,
  ballFrequency,
  ballPercent,
  //allChooseDigits,
  //gameDuration,
  allDigitsOfTickets,
  drawHistory,
  userHistoryDraw,
  chooseWinNumber,
  error,
  gameDurationBetInfoProfileBalance,
  chooseDigitsDigit,
  pressButton,
  currentGameHistoryAndProfileBalance,
  digitsActive,
  allDigitsActiveFalse,
  currentHistoryAndBallFrequencyAndDrawHistoryAndUserHistoryDrawAndCompareWin,
  raceIdAndProfileBalance,
  changeStart,
  changeMobileHistoryPopUp,
  changeMobileStatistics,
  changeMobileHistoryMenuState,
  changeMobileRoulletePage,
  chnageCurrentBetValue,
  changeProfileBalanceAfterWin,
  returnedWinNumber,
  autoClearUserHistoryDraw,
  errorAmountPalance,
  handleErrorMassageWithoutChangeStart,
} from './actions';

export const setCorrectView = (data) => (dispatch) => {
  dispatch(correctView(data));
};

export const setDigitsCount = (count) => (dispatch) => {
  dispatch(digitsCount(count));
};

export const setHowToPlay = (data) => (dispatch) => {
  dispatch(howToPlay(data));
};

export const handleChangeStartAndProfileBalance = (demo, demoBalance) => (dispatch) => {
  const obj = {
    demo,
    demoBalance,
  };
  dispatch(changeStartAndProfileBalance(obj));
};

export const handleChangeStart = (demo) => (dispatch) => {
  dispatch(changeStart(demo));
};

export const handleGetBet = (data) => (dispatch) => {
  dispatch(getBet(data));
};

export const changeProfileBalance =
  (balance, arr, myHistory = []) =>
  (dispatch) => {
    let result = [...myHistory];
    if (arr) {
      result = result.filter((item) => item.draw !== arr[0]?.draw);
    } else {
      arr = [...result];
      result = [];
    }
    arr.forEach((item) => result.push(item));
    if (result.length) {
      result = result.sort((a, b) => b.draw - a.draw);
    }
    const obj = {
      balance,
      result,
    };
    dispatch(profileBalance(obj));
  };

export const setGameDurationBetInfoProfileBalance =
  (gameDuration, betInfo, profileBalance, digits) => (dispatch) => {
    const obj = {
      gameDuration,
      betInfo,
      profileBalance,
      digits,
    };
    dispatch(gameDurationBetInfoProfileBalance(obj));
  };

export const setBetInfo = (data) => (dispatch) => {
  //dispatch(betInfo(data.res.parsedData.data));
};

export const setRaceId = (data) => (dispatch) => {
  dispatch(raceId(data));
};

export const getWinNumber = (data, active) => (dispatch) => {
  dispatch(winNumber(data, active));
};

export const getHistory =
  (usersCurrentDrawStakes, ballFrequency, ballPercent, drawHistory, userHistoryDraw, number, start) =>
  (dispatch) => {
    const obj = {
      usersCurrentDrawStakes,
      ballFrequency,
      ballPercent,
      drawHistory,
      userHistoryDraw: start !== 'demo' ? userHistoryDraw : [],
      number,
    };
    dispatch(history(obj));
  };

export const addToCurrentGameHistoryAndMyHistory =
  (bet, price, id, raceId, date, currentGameHistory, userHistoryDraw, balance) => (dispatch) => {
    const arrCurrentGameHistory = [...currentGameHistory];
    const arrUerHistory = [...userHistoryDraw];
    const objCurrentGame = {
      dt: date,
      sum: price,
      bet,
      id,
      draw_id: raceId,
      package_sum: price,
      r: '',
      status: 0,
      wsum: 0,
    };
    const objUserHistory = {
      bet,
      draw: raceId,
      id,
      package_sum: price,
      r: '',
      status: 0,
      wsum: 0,
      dt: date,
    };
    arrCurrentGameHistory.push(objCurrentGame);
    arrUerHistory.push(objUserHistory);
    const obj = {
      arrCurrentGameHistory,
      arrUerHistory,
      balance,
    };
    dispatch(addCurrentGameHistoryAndMyHistory(obj));
  };

export const getBallFrequency = (data) => (dispatch) => {
  dispatch(ballFrequency(data));
};
export const getBallPercent = (data) => (dispatch) => {
  dispatch(ballPercent(data))
}

//export const getAllChooseDigits = (data) => (dispatch) => {
//  dispatch(allChooseDigits(data));
//}

export const getStartGameDuration = (data) => (dispatch) => {};

export const setAllDigitsOfTickets = (data, boolean, number) => (dispatch) => {
  if (boolean) {
    const result = { ...data };
    if (result[number]) {
      delete result[number];
    } else {
      result[number] = number;
    }
    dispatch(allDigitsOfTickets(result));
  } else {
    dispatch(allDigitsOfTickets(data));
  }
};

export const getDrawHistory =
  (data = [], mainData = [], isAdd = false) =>
  (dispatch) => {
    const arrData = [...data];
    const mainArrData = [...mainData];
    if (isAdd) {
      arrData.forEach((item) => mainArrData.push(item));
      dispatch(drawHistory(mainArrData));
    } else {
      dispatch(drawHistory(arrData));
    }
  };

export const getUserHistoryDraw =
  (data = [], mainData = [], isAdd = false) =>
  (dispatch) => {
    const arrData = [...data];
    const mainArrData = [...mainData];
    if (isAdd) {
      arrData.forEach((item) => mainArrData.push(item));
      dispatch(userHistoryDraw(mainArrData));
    } else {
      dispatch(userHistoryDraw(data));
    }
  };

export const compareWinNumber = (data) => (dispatch) => {
  dispatch(chooseWinNumber(data));
};

export const setError = (data) => (dispatch) => {
  dispatch(error(data));
};

export const changeDigits = (currentData, data, count, isOne) => (dispatch) => {
  if (isOne) {
    data[currentData].active = !data[currentData].active;
    dispatch(chooseDigitsDigit(data));
  } else {
    const result = { ...data };
    const [str, property] = currentData;
    const obj = {};
    const arr = Object.entries(result);
    let isAllActive = true;
    const filteredDigits = Object.values(data).filter((digit) => digit[property] === str);
    for (let i = 0; i < filteredDigits.length; i++) {
      if (!filteredDigits[i].active) {
        isAllActive = false;
        break;
      }
    }
    arr.forEach(([key, value]) => {
      if (value[property] === str) {
        value.active = !isAllActive;
      } else if (!isAllActive) {
        value.active = false;
      }
      obj[key] = value;
    });
    let finalCount = 0;
    Object.values(obj).forEach((digit) => {
      if (digit.active) {
        finalCount += 1;
      }
    });
    const newObj = {
      obj,
      count: finalCount,
    };
    dispatch(chooseDigitsDigit(newObj));
  }
};

export const isPressButtonOk = (data) => (dispatch) => {
  dispatch(pressButton(data));
};

// export const setCurrentGameHistoryAndProfileBalance = (userBalance, bet, amountValue, ticketId, currentGameHistory) => (dispatch) => {
//   const arr = [...currentGameHistory];
//   arr.unshift({
//     dt: new Date(),
//     sum: amountValue,
//     bet,
//     id: ticketId
//   });
//   const obj = {
//     currentGameHistory: arr,
//     profileBalance: userBalance,
//   }
//   dispatch(currentGameHistoryAndProfileBalance(obj));
// };

export const changeDigitsActive = (number, data, count) => (dispatch) => {
  const result = { ...data };
  result[number].active = !result[number].active;
  const obj = {
    result,
    count: result[number].active ? count + 1 : count - 1,
  };
  dispatch(digitsActive(obj));
};

export const changeAllDigitsActiveFalse = (data) => (dispatch) => {
  const obj = {};
  Object.entries(data).forEach(([key, value]) => {
    value.active = false;
    obj[key] = value;
  });
  const newObj = {
    result: obj,
    count: 0,
  };
  dispatch(allDigitsActiveFalse(newObj));
};

export const setCurrentHistoryAndBallFrequencyAndDrawHistoryAndUserHistoryDrawAndCompareWin =
  (currentGameHistory, ballFrequency, drawHistory, userHistoryDraw, userBalance) => (dispatch) => {
    const obj = {
      currentGameHistory,
      ballFrequency,
      drawHistory,
      userHistoryDraw,
      userBalance,
    };
    dispatch(currentHistoryAndBallFrequencyAndDrawHistoryAndUserHistoryDrawAndCompareWin(obj));
  };

export const setRaceIdAndProfileBalance = (raceId, profileBalance) => (dispatch) => {
  const obj = { raceId, profileBalance };
  dispatch(raceIdAndProfileBalance(obj));
};

export const handleChangeMobileHistoryPopUp = (boolean) => (dispatch) => {
  dispatch(changeMobileHistoryPopUp(boolean));
};

export const handleChangeMobileStatistics = (boolean) => (dispatch) => {
  dispatch(changeMobileStatistics(boolean));
};

export const handleChangeMobileHistoryMenuState = (data) => (dispatch) => {
  dispatch(changeMobileHistoryMenuState(data));
};

export const handleChangeMobileRoulletePage = (data) => (dispatch) => {
  dispatch(changeMobileRoulletePage(data));
};

export const handleChnageCurrentBetValue = (data) => (dispatch) => {
  dispatch(chnageCurrentBetValue(data));
};

export const getReturnedWinNumber = () => (dispatch) => {
  dispatch(returnedWinNumber());
};

export const clearUserHistoryDraw = () => (dispatch) => {
  dispatch(autoClearUserHistoryDraw());
};

export const errorBalance = (data) => (dispatch) => {
  dispatch(errorAmountPalance(data));
};

export const errorMassageWithoutChangeStart = (data) => (dispatch) => {
  dispatch(handleErrorMassageWithoutChangeStart(data));
};
