import React, { useReducer, useEffect, useContext, useCallback } from 'react';

export const TranslateContext = React.createContext(null);

const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
const SET_NEW_OBJECT = 'SET_NEW_OBJECT';

function reducer(state, action) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return { ...state, currentLanguage: action.payload };
    case SET_NEW_OBJECT:
      return { ...state, obj: action.payload };
    default:
      return state;
  }
}

export function TranslateProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, {
    currentLanguage: window.location.pathname.split('/')[2] || 'en',
    obj: {},
  });

  useEffect(() => {
    localStorage.setItem('fallbackLanguage', window.location.pathname.split('/')[2] || 'en');
  }, []);

  useEffect(() => {
    const controller = new AbortController();

    try {
      (async function () {
        const response = await fetch(`/translate/${state.currentLanguage}.json`, {
          signal: controller.signal,
        });
        const string = await response.text();
        if (string) {
          dispatch({ type: SET_NEW_OBJECT, payload: JSON.parse(string) });
        }
      })();
    } catch (err) {
      console.log(err);
    }

    return () => {
      controller.abort();
    };
  }, [state.currentLanguage, dispatch]);

  return (
    <TranslateContext.Provider value={[state, dispatch]}>{children}</TranslateContext.Provider>
  );
}

export function useTranslate() {
  const [state, dispatch] = useContext(TranslateContext);
  const t = useCallback(
    (key) => {
      return state.obj[key] || key;
    },
    [state],
  );
  const changeLanguage = useCallback(
    (lng) => {
      localStorage.fallbackLanguage = lng;
      dispatch({ type: CHANGE_LANGUAGE, payload: lng });
    },
    [dispatch],
  );
  return [t, changeLanguage];
}
