import React, { lazy, Suspense, useCallback, useEffect, useMemo, useRef } from 'react';
import { useCorrectHtml } from '../../customHooks/useCorrectHtml';
import { isFullScreen } from '../../helperFunctions/fullScreen';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../../helperFunctions/requests';
import { rouletteDigits } from '../../helperFunctions/rouletteDigits';
import {
  setHowToPlay,
  getWinNumber,
  isPressButtonOk,
  setError,
  setCurrentHistoryAndBallFrequencyAndDrawHistoryAndUserHistoryDrawAndCompareWin,
  handleChangeStart,
  handleChangeMobileRoulletePage,
  getReturnedWinNumber,
  changeAllDigitsActiveFalse,
} from '../../redux/actions';
import { raceId as setRaceId } from '../../redux/actions/actions';
import { correctInitialTimeShow, correctTimerShow } from '../../helperFunctions/correctTimerShow';

const WebHeader = lazy(() =>
  import('./template').then((module) => ({ default: module.WebHeader })),
);
const MobileHeader = lazy(() =>
  import('./template').then((module) => ({ default: module.MobileHeader })),
);

const two360 = 10;
const two360Deg = 720;

function Header() {
  const Component = useCorrectHtml(WebHeader, MobileHeader);
  const { start, gameDuration, screen, oneCircle, error, digits } = useSelector((state) => state);
  const dispatch = useDispatch();

  const timeLine = useRef(null);
  const timeText = useRef(null);

  const token = useMemo(() => {
    return localStorage.getItem('token');
  }, []);

  const openHowToPlay = useCallback(
    (e) => {
      if (error) return;
      e.stopPropagation();
      dispatch(setHowToPlay(true));
    },
    [dispatch, error],
  );

  const oneMsPercent = useMemo(() => {
    if (screen > 1023) {
      return gameDuration === 1 ? 0.00145 : 0.00058;
    } else {
      return gameDuration === 1 ? 0.00151 : 0.000605;
    }
  }, [screen]);

  const handleFullScreen = useCallback(() => {
    if (error) return;
    const root = document.querySelector('#root');

    // if (document.webkitFullscreenElement) {
    //   document.webkitCancelFullScreen();
    // } else {
    //   const el = document.documentElement;
    //   el.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    // }

    if (!isFullScreen()) {
      if (root.requestFullscreen) {
        root.requestFullscreen();
      } else if (root.msRequestFullscreen) {
        root.msRequestFullscreen();
      } else if (root.mozRequestFullScreen) {
        root.mozRequestFullScreen();
      } else if (root.webkitRequestFullscreen) {
        root.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }, [error]);

  const allRouletteDigits = useMemo(() => rouletteDigits(), []);

  useEffect(() => {
    const controller = new AbortController();
    const abort = { signal: controller.signal };

    let requestAnimation = 1;
    let intervalId;
    let checkWinNumberId;
    let rouletteRequestAnimation;

    if (start === 'start') {
      (async () => {
        try {
          const { data } = await getRequest(
            'get-time',
            {
              duration: gameDuration,
            },
            { token },
          );
          const { data: { time = 0, raceId } = {} } = data;
          if (!error) {
            dispatch(setRaceId(raceId));
          }
        } catch (error) {}
      })();
    }

    if (start !== 'start') {
      const deferAboutFourSecond = {};
      deferAboutFourSecond.promise = new Promise((resolve, reject) => {
        deferAboutFourSecond.resolve = resolve;
        deferAboutFourSecond.reject = reject;
      });
      const deferOnEndSecond = {};
      deferOnEndSecond.promise = new Promise((resolve, reject) => {
        deferOnEndSecond.resolve = resolve;
        deferOnEndSecond.reject = reject;
      });
      const deferIsPressButton = {};
      deferIsPressButton.promise = new Promise((resolve, reject) => {
        deferIsPressButton.resolve = resolve;
        deferIsPressButton.reject = reject;
      });

      try {
        (async () => {
          const { data } = await getRequest(
            'get-time',
            {
              duration: gameDuration,
            },
            { token },
          );
          const { data: { time = 0, raceId } = {} } = data;
          if (error) throw Error(error);
          dispatch(setRaceId(raceId));
          const nextRaceStart = Date.now();
          const gameInitialDuration = gameDuration === 1 ? 59 : 150;
          let inheritanceWidth = (gameInitialDuration - time) * 1000 * oneMsPercent;
          const animation = () => {
            const now = Date.now();
            const timer = now - nextRaceStart;
            const currentWidth = inheritanceWidth + timer * oneMsPercent;
            const currentTimeStep = time - +(timer / 1000).toFixed();
            if (timeLine.current && currentTimeStep <= gameInitialDuration) {
              timeLine.current.style.width = currentWidth + '%';
            }
            if (currentTimeStep < gameInitialDuration) {
              if (timeText.current) {
                timeText.current.innerHTML = correctTimerShow(currentTimeStep);
                deferIsPressButton.resolve();
              }
            } else {
              if (timeText.current) {
                timeText.current.innerHTML = correctInitialTimeShow(gameDuration);
              }
            }
            if (currentTimeStep < 5) deferAboutFourSecond.resolve();
            if (currentTimeStep < 0) deferOnEndSecond.resolve();
            if (currentTimeStep >= 0) {
              requestAnimation = requestAnimationFrame(animation);
            }
          };
          requestAnimation = requestAnimationFrame(animation);
        })();
      } catch (err) {
        console.log(err);
        throw new Error(err);
      }
      const promise = new Promise((resolve, reject) => {
        deferIsPressButton.promise.then(() => {
          dispatch(isPressButtonOk(true));
        });

        deferAboutFourSecond.promise.then(() => {
          dispatch(isPressButtonOk(false));
        });

        deferOnEndSecond.promise.then(() => {
          timeLine.current.style.width = 0;
          timeText.current.innerHTML = correctInitialTimeShow(gameDuration);
          dispatch(handleChangeMobileRoulletePage(true));
          getRequest(
            'get-user-draw-history',
            {
              duration: gameDuration,
              limit: '20',
              page: '0',
            },
            { token },
          ).then((result) => {
            const ballFrequency = result?.data.data.sendData.ballFrequency;
            const drawHistory = result?.data.data.sendData.drawHistory;
            const userHistory = result?.data.data.sendData.userHistoryDraw;
            const userHistoryInArray = JSON.parse(JSON.stringify(userHistory));
            userHistoryInArray.forEach((item) => (item.bet = item.bet.split(',')));
            const usersCurrentDrawStakes = result?.data.data.sendData.usersCurrentDrawStakes;
            const usersCurrentDrawStakesInArray = JSON.parse(
              JSON.stringify(usersCurrentDrawStakes),
            );
            usersCurrentDrawStakesInArray.forEach((item) => (item.bet = item.bet.split(',')));

            const [{ r: winNumber = 11 }] = drawHistory;
            if (!error) {
              const userHistoryDraw = userHistoryInArray.map((item) => {
                return {
                  ...item,
                  dt: item.date,
                };
              });
              resolve({
                drawHistory,
                userHistoryDraw,
                ballFrequency,
                currentGameHistory: usersCurrentDrawStakesInArray,
                winNumber,
              });
            } else {
              throw new Error(error);
            }
          });
        });
      });

      promise
        .then((result) => {
          const { winNumber } = result;
          const rouletteMilliSeconds = two360 + +allRouletteDigits[winNumber].s;
          const rouletteDeg =
            screen <= 1023
              ? two360Deg + allRouletteDigits[winNumber].deg - 19.459459459
              : two360Deg + allRouletteDigits[winNumber].deg;

          const cssVariables = new Promise((resolve, reject) => {
            document.documentElement.style.setProperty(
              '--rouletteStep',
              `${rouletteMilliSeconds}s`,
            );
            document.documentElement.style.setProperty('--stopDeg', `${rouletteDeg}deg`);
            resolve();
          }).then(() => dispatch(getWinNumber(winNumber, true)));

          const weelRotate = new Promise((resolve, reject) => {
            const currentDate = Date.now();
            const rouletteMilliseconds = two360 + +allRouletteDigits[result.winNumber].s;

            intervalId = setInterval(() => {
              let timer = Date.now() - currentDate;
              if (timer / 1000 > rouletteMilliseconds) {
                clearInterval(intervalId);
                resolve(result);
              }
            }, 100);
          });
          return Promise.all([weelRotate, cssVariables]);
        })
        .then(([result]) => {
          const { winNumber } = result;
          dispatch(getWinNumber(winNumber, false));
          const comparePromise = new Promise((resolve, reject) => {
            checkWinNumberId = setTimeout(() => {
              resolve();
              clearTimeout(checkWinNumberId);
            }, 3000);
          });
          const balancePromise = new Promise((resolve, reject) => {
            if (start === 'play') {
              (async () => {
                const { data } = await getRequest(
                  'get-balance',
                  { duration: gameDuration },
                  { token },
                );
                const userBalance = data?.data.balance;
                if (!error) {
                  const obj = { ...result };
                  obj.userBalance = userBalance;
                  resolve(obj);
                } else {
                  reject('Error');
                }
              })();
            } else {
              const obj = { ...result };
              resolve(obj);
            }
          });
          return Promise.all([balancePromise, comparePromise]);
        })
        .then(([result]) => {
          const {
            currentGameHistory = [],
            ballFrequency = [],
            drawHistory = [],
            userHistoryDraw = [],
            userBalance,
          } = result;
          dispatch(
            setCurrentHistoryAndBallFrequencyAndDrawHistoryAndUserHistoryDrawAndCompareWin(
              currentGameHistory,
              ballFrequency,
              drawHistory,
              userHistoryDraw,
              userBalance,
            ),
          );
        })
        .catch((err) => {
          console.log(err);
          dispatch(setError('Something went wrong!'));
        });
    }

    return () => {
      controller.abort();
      cancelAnimationFrame(requestAnimation);
      clearInterval(intervalId);
      cancelAnimationFrame(intervalId);
      clearTimeout(checkWinNumberId);
      if (screen > 1023) {
        dispatch(getReturnedWinNumber());
      } else {
        dispatch(handleChangeMobileRoulletePage(false));
      }
    };
  }, [dispatch, gameDuration, start, oneCircle, allRouletteDigits, token, screen, error]);

  const handleReturnStartState = useCallback(() => {
    if (start === 'start') {
      window.parent.postMessage(
        { action: 'EGHome', key: 'EGWof', type: screen > 1023 ? 'desktop' : 'mobile' },
        '*',
      );
    } else {
      dispatch(changeAllDigitsActiveFalse(digits));
      dispatch(handleChangeStart('start'));
    }
  }, [dispatch, start, screen]);

  return (
    <Suspense fallback={''}>
      <Component
        timeLine={timeLine}
        handleFullScreen={handleFullScreen}
        openHowToPlay={openHowToPlay}
        forwardedRef={timeText}
        start={start}
        handleReturnStartState={handleReturnStartState}
        hidden={start === 'start' ? true : false}
        timeText={timeText}
      />
    </Suspense>
  );
}

export default Header;
