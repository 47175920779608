import React, { useEffect, useState } from 'react';
import Header from './header';
import Wrapper from './wrapper';
import isMobile from 'ismobilejs';
import { useDispatch, useSelector } from 'react-redux';
import { setCorrectView } from '../redux/actions';
import phone from '../assets/img/global/phone.svg';
import '../assets/scss/App.scss';
import { GAME_TYPES } from '../constants/names';
import { setGameType } from '../redux/actions/actions';

const { WOF, SPINNING } = GAME_TYPES;

const isDevice = isMobile(document.window).any;

function App() {
  const dispatch = useDispatch();
  const { start } = useSelector((state) => state);
  const [isRotate, setIsRotate] = useState(() => !!window.orientation);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const {token, language, type} = Object.fromEntries(urlSearchParams.entries());

    localStorage.setItem("fallbackLanguage", language ? language : "en");
    localStorage.setItem("token", token);
    localStorage.setItem("type", type);

    dispatch(setGameType(type === "spinningwheel" ? SPINNING : WOF));
}, []);

  useEffect(() => {
    const isRotateFalse = (e) => {
      if (window.orientation) {
        setIsRotate(true);
      } else {
        setIsRotate(false);
      }
    };
    window.addEventListener('orientationchange', isRotateFalse);
    return () => window.removeEventListener('orientationchange', isRotateFalse);
  }, []);

  useEffect(() => {
    const body = document.querySelector('body');
    dispatch(setCorrectView({ screen: body.getBoundingClientRect().width, isMobile: isDevice }));
    const onResize = () => {
      dispatch(setCorrectView({ screen: body.getBoundingClientRect().width, isMobile: isDevice }));
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [dispatch]);

  return (
    <>
      <div className="wof">
        {start === 'start' ? (
          <div className="demo__field">
            <Header />
            <Wrapper />
          </div>
        ) : (
          <>
            <Header />
            <Wrapper />
          </>
        )}
      </div>
      <div className="rotate__none" style={{ display: isRotate ? 'flex' : 'none' }}>
        <div className="rotate__image active">
          <img src={phone} alt="phone" />
        </div>
      </div>
    </>
  );
}

export default App;
