import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { reducer } from './redux/reducer/index';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { TranslateProvider } from './helperFunctions/translate';

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

ReactDOM.render(
    <StrictMode >
        <Provider store={store} >
            <TranslateProvider >
                <App />
            </TranslateProvider>
        </Provider>
    </StrictMode>
    , document.getElementById('root')
);

serviceWorker.unregister();