export function rouletteDigits() {
  const s = 0.28;
  const deg = 9.72972973;
  const result = {
    11: { deg: 0 * deg, s: 0 * s },
    36: { deg: deg, s: s },
    13: { deg: 2 * deg, s: 2 * s },
    27: { deg: 3 * deg, s: 3 * s },
    6: { deg: 4 * deg, s: 4 * s },
    34: { deg: 5 * deg, s: 5 * s },
    17: { deg: 6 * deg, s: 6 * s },
    25: { deg: 7 * deg, s: 7 * s },
    2: { deg: 8 * deg, s: 8 * s },
    21: { deg: 9 * deg, s: 9 * s },
    4: { deg: 10 * deg, s: 10 * s },
    19: { deg: 11 * deg, s: 11 * s },
    15: { deg: 12 * deg, s: 12 * s },
    32: { deg: 13 * deg, s: 13 * s },
    0: { deg: 14 * deg, s: 14 * s },
    26: { deg: 15 * deg, s: 15 * s },
    3: { deg: 16 * deg, s: 16 * s },
    35: { deg: 17 * deg, s: 17 * s },
    12: { deg: 18 * deg, s: 18 * s },
    28: { deg: 19 * deg, s: 19 * s },
    7: { deg: 20 * deg, s: 20 * s },
    29: { deg: 21 * deg, s: 21 * s },
    18: { deg: 22 * deg, s: 22 * s },
    22: { deg: 23 * deg, s: 23 * s },
    9: { deg: 24 * deg, s: 24 * s },
    31: { deg: 25 * deg, s: 25 * s },
    14: { deg: 26 * deg, s: 26 * s },
    20: { deg: 27 * deg, s: 27 * s },
    1: { deg: 28 * deg, s: 28 * s },
    33: { deg: 29 * deg, s: 29 * s },
    16: { deg: 30 * deg, s: 30 * s },
    24: { deg: 31 * deg, s: 31 * s },
    5: { deg: 32 * deg, s: 32 * s },
    10: { deg: 33 * deg, s: 33 * s },
    23: { deg: 34 * deg, s: 34 * s },
    8: { deg: 35 * deg, s: 35 * s },
    30: { deg: 36 * deg, s: 36 * s },
  };
  return result;
}
export function getSoundSecond(s) {
  return 15 / (s + 10);
}
