import React, { Suspense, lazy } from 'react';
import { useCorrectHtml } from '../../customHooks/useCorrectHtml';

const WebWrapper = lazy(() => import('./template').then(module => ({ default: module.WebWrapper })));
const MobileWrapper = lazy(() => import('./template').then(module => ({ default: module.MobileWrapper })));

function Wrapper() {

  const Component = useCorrectHtml(WebWrapper, MobileWrapper);

  return (
    <Suspense fallback={''} >
      <Component />
    </Suspense>
  );
};

export default Wrapper;