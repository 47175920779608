export const correctView = (data) => ({ type: 'SET_CORRECT_VIEW', payload: data });
export const setGameType = (data) => ({ type: 'SET_GAME_TYPE', payload: data });
export const digitsCount = (count) => ({ type: 'SAVE_DIGITS_COUNT', payload: count });
export const howToPlay = (data) => ({ type: 'HOW_TO_PLAY', payload: data });
export const changeStartAndProfileBalance = (data) => ({
  type: 'CHANGE_START_AND_PROFILE_BALANCE',
  payload: data,
});
export const changeStart = (data) => ({ type: 'CHANGE_START', payload: data });
export const getBet = (data) => ({ type: 'GET_CHOOSE_TICKETS', payload: data });
export const profileBalance = (data) => ({ type: 'CHANGE_PROFILE_BALANCE', payload: data });
//export const betInfo = (data) => ({ type: 'SET_BET_INFORMATION', payload: data });
export const raceId = (data) => ({ type: 'SET_RACE_ID', payload: data });
export const winNumber = (data, active) => ({ type: 'GET_WIN_NUMBER', payload: { data, active } });
export const history = (data) => ({ type: 'GET_HISTORY', payload: data });
export const addCurrentGameHistoryAndMyHistory = (data) => ({
  type: 'ADD_CURRENT_GAME_HISTORY_AND_MY_HISTORY',
  payload: data,
});
export const currentTicketId = (data) => ({ type: 'GET_CURRENT_TICKET_ID', payload: data });
export const ballFrequency = (data) => ({ type: 'GET_BALL_FREQUENCY', payload: data });
export const ballPercent = (data) => ({ type: 'GET_BALL_PERCENT', payload: data });
//export const allChooseDigits = (data) => ({ type: 'GET_ALL_CHOOSE_DIGITS', payload: data });
//export const gameDuration = (data) => ({ type: 'GET_START_GAME_DURATION', payload: data });
export const allDigitsOfTickets = (data) => ({ type: 'SET_ALL_DIGITS_OF_TICKETS', payload: data });
export const drawHistory = (data) => ({ type: 'GET_DRAW_HISTORY', payload: data });
export const userHistoryDraw = (data) => ({ type: 'GET_USER_HISTORY_DRAW', payload: data });
export const chooseWinNumber = (data) => ({ type: 'CHOOSE_WIN_NUMBER', payload: data });
export const error = (data) => ({ type: 'SET_ERROR_MESSAGE', payload: data });
export const gameDurationBetInfoProfileBalance = (data) => ({
  type: 'GET_START_GAME_DURATION_BET_INFO_PROFILE_BALANCE',
  payload: data,
});
export const chooseDigitsDigit = (data) => ({ type: 'CHOOSE_DIGITS_DIGIT', payload: data });
export const pressButton = (data) => ({ type: 'IS_PRESS_BUTTON', payload: data });
export const currentGameHistoryAndProfileBalance = (data) => ({
  type: 'SET_CURRENT_GAME_HISTORY_AND_PROFILE_BALANCE',
  payload: data,
});
export const digitsActive = (data) => ({ type: 'CHANGE_DIGITS_ACTIVE', payload: data });
export const allDigitsActiveFalse = (data) => ({ type: 'CHANGE_DIGITS_ACTIVE', payload: data });
export const currentHistoryAndBallFrequencyAndDrawHistoryAndUserHistoryDrawAndCompareWin = (
  data,
) => ({
  type: 'SET_CURRENT_HISTORY_BALL_FREQUENCY_DRAW_HISTORY_USER_DRAW_HISTORY_COMPARE_WIN_NUMBER',
  payload: data,
});
export const raceIdAndProfileBalance = (data) => ({
  type: 'SET_RACE_ID_AND_PROFILE_BALANCE',
  payload: data,
});
export const changeAllDigitsActiveToFalse = (data) => ({
  type: 'SET_ALL_DIGITS_ACTIVE_TO_FALSE_AND_COUNT_0',
  payload: data,
});
export const changeMobileHistoryPopUp = (data) => ({
  type: 'CHANGE_MOBILE_HISTORY_POPUP',
  payload: data,
});
export const changeMobileStatistics = (data) => ({
  type: 'CHANGE_MOBILE_STATISTICS',
  payload: data,
});
export const changeMobileHistoryMenuState = (data) => ({
  type: 'CHANGE_MOBILE_HISTORY_MENU_STATE',
  payload: data,
});
export const changeMobileRoulletePage = (data) => ({
  type: 'CHANGE_MOBILE_ROULLETE_PAGE_STATE',
  payload: data,
});
export const chnageCurrentBetValue = (data) => ({
  type: 'CHANGE_CURRENT_BET_VALUE',
  payload: data,
});
export const returnedWinNumber = () => ({ type: 'RETURNED_WIN_NUMBER' });
export const autoClearUserHistoryDraw = () => ({ type: 'AUTO_CLEAR_USER_HISTORY_DRAW' });
export const errorAmountPalance = (data) => ({ type: 'ERROR_BALANCE', payload: data });
export const handleErrorMassageWithoutChangeStart = (data) => ({
  type: 'SET_ERROR_MESSAGE_WITHOUT_CHANGE_START',
  payload: data,
});
export const toggleSound = () => ({ type: 'TOGGLE_SOUND' });

export const setBurgerMenu = () => ({ type: 'IS_BURGE_MENU_ACTIVE' });
export const showDemoPopUp = (payload) => ({ type: 'SHOW_DEMO_POPUP', payload });

export const changeGameDuration = (payload) => ({type: 'CHANGE_GAME_DURATION', payload})
