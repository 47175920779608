import axios from 'axios';
const url = '/api/v1';

// DEVELOPMENT
// const url = 'http://oliver.energaming.systems:20074/api/v1'; // DEVELOPMENT DB
// /?token=test-frontend-token // DEVELOPMENT TOKEN

export async function getRequest(mainApi, params, headers) {
  try {
    const response = await axios.get(`${url}/${mainApi}`, { params, headers });
    if (response) {
      return response;
    }
  } catch (err) {
    return new Error(err);
  }
}

export async function postSendInfo(mainApi, body, headers) {
  try {
    const response = await axios.post(`${url}/${mainApi}`, body, { headers });
    if (response) {
      return response;
    }
  } catch (err) {
    return new Error(err);
  }
}
