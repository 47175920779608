import { useSelector } from 'react-redux';

function Empty() {
  return null;
};

export function useCorrectHtml(Web, Mobile) {

  const { screen } = useSelector(state => state);

  if (screen <= 1023) {
    if (Mobile) return Mobile
    return Empty;
  };
  return Web;
};