import { raceId } from '../actions/actions';

const initialState = {
  isMobile: null,
  gameType: null,
  screen: null,
  digitsCount: 0,
  howToPlay: false,
  start: 'start',
  profileBalance: null,
  winNumber: 11,
  chooseWinNumber: false,
  winActive: false,
  bet: [],
  betInfo: {
    denomination: null,
    maxBet: null,
    maxWin: null,
    minBet: null,
    mode: null,
  },
  raceId: null,
  currentGameHistory: [],
  currentTicketId: null,
  ballFrequency: [],
  ballPercent: {},
  chooseDigits: {},
  gameDuration: 1,
  allDigitsOfTickets: [],
  drawHistory: [],
  userHistoryDraw: [],
  error: '',
  digits: {},
  isPressButton: false,
  isMobileHistoryPopUp: false,
  isMobileStatistics: false,
  mobileMenuHistoryState: 'draw_history',
  mobileRoulletePage: false,
  betValue: 0,
  currency: '',
  oneCircle: true,
  sound: true,
  burgerMenu: false,
  isShowDemoPopUP: false,
};

export const reducer = (state = initialState, actions) => {
  switch (actions.type) {
    case 'SET_CORRECT_VIEW':
      const { isMobile, screen } = actions.payload;
      return {
        ...state,
        isMobile,
        screen,
      };
    case 'CHANGE_GAME_DURATION':
      return {
        ...state,
        gameDuration: actions.payload,
      };
    case 'SET_GAME_TYPE':
      const gameType = actions.payload;
      return {
        ...state,
        gameType,
      };
    case 'SAVE_DIGITS_COUNT':
      return {
        ...state,
        digitsCount: actions.payload,
      };
    case 'HOW_TO_PLAY':
      return {
        ...state,
        howToPlay: actions.payload,
      };
    case 'CHANGE_START_AND_PROFILE_BALANCE':
      const { demo, demoBalance } = actions.payload;
      return {
        ...state,
        start: demo,
        profileBalance: demoBalance,
      };
    case 'CHANGE_START':
      return {
        ...state,
        start: actions.payload,
      };
    case 'CHANGE_PROFILE_BALANCE':
      return {
        ...state,
        profileBalance: actions.payload.balance,
        userHistoryDraw: actions.payload.result,
      };
    case 'SET_BET_INFORMATION':
      return {
        ...state,
        betInfo: actions.payload,
      };
    case 'SET_RACE_ID':
      return {
        ...state,
        raceId: actions.payload,
      };
    case 'GET_CHOOSE_TICKETS':
      return {
        ...state,
        bet: actions.payload,
      };
    case 'GET_WIN_NUMBER':
      let resultAllDigits = { ...state.digits };
      resultAllDigits = Object.entries(resultAllDigits).map(([key, value]) => {
        return {
          ...value,
          win: false,
        };
      });
      return {
        ...state,
        winNumber: actions.payload.data,
        winActive: actions.payload.active,
        digits: actions.payload.active
          ? {
              ...resultAllDigits,
            }
          : {
              ...resultAllDigits,
              [actions.payload.data]: {
                ...resultAllDigits[actions.payload.data],
                win: true,
              },
            },
        chooseWinNumber: actions.payload.active ? false : true,
      };
    case 'GET_HISTORY':
      return {
        ...state,
        currentGameHistory: state.start !== 'demo' ? actions.payload.usersCurrentDrawStakes : [],
        ballFrequency: actions.payload.ballFrequency,
        ballPercent: actions.payload.ballPercent,
        drawHistory: actions.payload.drawHistory,
        userHistoryDraw:
          state.start !== 'demo' ? actions.payload.userHistoryDraw : state.userHistoryDraw,
        // userHistoryDraw: actions.payload.userHistoryDraw,
        winNumber: actions.payload.number,
      };
    case 'ADD_CURRENT_GAME_HISTORY_AND_MY_HISTORY':
      const { arrCurrentGameHistory, arrUerHistory, balance } = actions.payload;
      return {
        ...state,
        currentGameHistory: arrCurrentGameHistory,
        userHistoryDraw: arrUerHistory,
        profileBalance: balance,
      };
    case 'GET_BALL_FREQUENCY':
      return {
        ...state,
        ballFrequency: actions.payload,
      };
    //case 'GET_ALL_CHOOSE_DIGITS':
    //  return {
    //    ...state,
    //    chooseDigits: actions.payload
    //  };
    case 'GET_START_GAME_DURATION_BET_INFO_PROFILE_BALANCE':
      const { gameDuration = '', betInfo = {}, profileBalance = {}, digits = {} } = actions.payload;
      return {
        ...state,
        gameDuration,
        betInfo,
        profileBalance: profileBalance.userBalance,
        digits,
        betValue: betInfo.minBet,
        currency: profileBalance.currency,
      };
    case 'SET_ALL_DIGITS_OF_TICKETS':
      return {
        ...state,
        allDigitsOfTickets: actions.payload,
      };
    case 'GET_DRAW_HISTORY':
      return {
        ...state,
        drawHistory: actions.payload,
      };
    case 'GET_USER_HISTORY_DRAW':
      return {
        ...state,
        userHistoryDraw: actions.payload,
      };
    case 'CHOOSE_WIN_NUMBER':
      return {
        ...state,
        chooseWinNumber: actions.payload,
      };
    case 'SET_ERROR_MESSAGE':
      return {
        ...state,
        error: actions.payload,
        // start: 'start'
      };
    case 'SET_ERROR_MESSAGE_WITHOUT_CHANGE_START':
      return {
        ...state,
        error: actions.payload,
        // oneCircle: !state.oneCircle
      };
    case 'ERROR_BALANCE':
      return {
        ...state,
        error: actions.payload,
      };
    case 'CHOOSE_DIGITS_DIGIT':
      return {
        ...state,
        digits: actions.payload.obj,
        digitsCount: actions.payload.count,
      };
    case 'IS_PRESS_BUTTON':
      return {
        ...state,
        isPressButton: actions.payload,
      };
    case 'SET_CURRENT_GAME_HISTORY_AND_PROFILE_BALANCE':
      return {
        ...state,
        profileBalance: actions.payload.profileBalance,
        currentGameHistory: actions.payload.currentGameHistory,
      };
    case 'CHANGE_DIGITS_ACTIVE':
      const { result, count } = actions.payload;
      return {
        ...state,
        digits: result,
        digitsCount: count,
      };
    case 'SET_CURRENT_HISTORY_BALL_FREQUENCY_DRAW_HISTORY_USER_DRAW_HISTORY_COMPARE_WIN_NUMBER':
      const { currentGameHistory, ballFrequency, drawHistory, userHistoryDraw, userBalance } =
        actions.payload;
      const filteredDrawTickets = state.currentGameHistory.filter(
        (item) => item.draw_id !== state.raceId,
      );

      const nextTicketsHistory = state.userHistoryDraw.filter(
        (item) => item.draw === state.raceId + 1,
      );

      const drawMixedHistory = [...userHistoryDraw]

      nextTicketsHistory.forEach(ticket => {
        if(!drawMixedHistory.find(item => item.id === ticket.id )) {
          drawMixedHistory.push(ticket)
        }
      })

      return {
        ...state,
        currentGameHistory: filteredDrawTickets,
        ballFrequency,
        drawHistory,
        userHistoryDraw: state.start !== 'demo' ? drawMixedHistory : state.userHistoryDraw,
        profileBalance: userBalance ? userBalance : state.profileBalance,
        chooseWinNumber: false,
        mobileRoulletePage: false,
        oneCircle: !state.oneCircle,
      };
    case 'SET_RACE_ID_AND_PROFILE_BALANCE':
      return {
        ...state,
        raceId: actions.payload.raceId,
        profileBalance: actions.payload.profileBalance,
      };
    case 'SET_ALL_DIGITS_ACTIVE_TO_FALSE_AND_COUNT_0':
      return {
        ...state,
        digits: actions.payload,
        digitsCount: 0,
      };
    case 'CHANGE_MOBILE_HISTORY_POPUP':
      return {
        ...state,
        isMobileHistoryPopUp: actions.payload,
      };
    case 'CHANGE_MOBILE_STATISTICS':
      return {
        ...state,
        isMobileStatistics: !state.isMobileStatistics,
      };
    case 'CHANGE_MOBILE_HISTORY_MENU_STATE':
      return {
        ...state,
        mobileMenuHistoryState: actions.payload,
      };
    case 'CHANGE_MOBILE_ROULLETE_PAGE_STATE':
      return {
        ...state,
        mobileRoulletePage: actions.payload,
      };
    case 'CHANGE_CURRENT_BET_VALUE':
      return {
        ...state,
        betValue: actions.payload,
      };
    case 'RETURNED_WIN_NUMBER':
      let allDigits = { ...state.digits };
      allDigits = Object.entries(allDigits).map(([key, value]) => {
        return {
          ...value,
          win: false,
        };
      });
      return {
        ...state,
        winNumber: state.winNumber,
        chooseWinNumber: false,
        winActive: false,
        digits: allDigits,
      };
    case 'SHOW_DEMO_POPUP':
      return {
        ...state,
        isShowDemoPopUP: actions.payload,
      };
    default:
      return state;
    case 'TOGGLE_SOUND':
      return {
        ...state,
        sound: !state.sound,
      };
    case 'IS_BURGE_MENU_ACTIVE':
      return {
        ...state,
        burgerMenu: !state.burgerMenu,
      };
  }
};
